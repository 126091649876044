import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"
import Pager from "../Pager"
import device from "../../util/devices"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const SlidePager = styled(Pager)`
  color: white;
  position: absolute;
  bottom: 5px;
  left: 10px;

  @media (${device.desktop}) {
    bottom: 15px;
    left: 20px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 250ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  > :first-child {
    flex: 1;
  }

  ${props =>
    !props.visible &&
    css`
      opacity: 0;
    `};
`

const Slideshow = ({ images, ...props }) => {
  const icons = useStaticQuery(graphql`
    {
      next: datoCmsAsset(filename: { eq: "cursor-next.svg" }) {
        url
      }
    }
  `)
  const [current, setCurrent] = useState(0)
  return (
    <div
      {...props}
      style={{
        position: "relative",
        cursor: images.length > 1 ? `url(${icons.next.url}), pointer` : null,
      }}
      onClick={() => setCurrent(c => (c + 1 < images.length ? c + 1 : 0))}
    >
      <Img {...images[current]} />
      <SlidePager index={current + 1} total={images.length} />
      {props.children && (
        <Overlay visible={current === 0}>{props.children}</Overlay>
      )}
    </div>
  )
}

export default Slideshow
