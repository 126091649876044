import React from "react"
import styled from "styled-components"

export const PagerContainer = styled.div`
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
  > :first-child::after {
    display: inline-block;
    vertical-align: middle;
    content: "";
    height: 1px;
    background: white;
    width: 3em;
    margin: 0 0.75em;
  }
`

const Pager = ({ index, total, ...props }) => (
  <PagerContainer {...props}>
    <span>{index}</span>
    <span>{total}</span>
  </PagerContainer>
)

export default Pager
