import React, { useState } from "react"
import Img from "gatsby-image"
import { ImageCaption } from "../Texts"
import styled, { css } from "styled-components"

const Container = styled.article`
  display: flex;
  flex-direction: column;

  > :first-child {
    flex: 1;
    position: relative;

    .gatsby-image-wrapper {
      transition: opacity 250ms ease-out;
    }

    .gatsby-image-wrapper:nth-child(2) {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;

      &:hover {
        opacity: 1;

        & ~ .gatsby-image-wrapper {
          opacity: 0;
        }
      }
    }
  }

  > :nth-child(2) {
    margin-top: auto;
  }
`

const VariationContainer = styled.div`
  display: inline;
  &:not(:last-child)::after {
    content: " | ";
    text-decoration: none;
  }
`

const VariationLabel = styled.span`
  ${props =>
    props.current
      ? css`
          text-decoration: underline;
        `
      : css`
          cursor: pointer;
        `};
`

const ProductCard = ({ name, variations }) => {
  const [currentVariation, setCurrentVariation] = useState(variations[0])
  const [currentImage, setCurrentImage] = useState(0)
  return (
    <Container>
      <div
        onMouseEnter={() =>
          currentVariation.images.length > 1 && setCurrentImage(1)
        }
        onMouseLeave={() => setCurrentImage(0)}
      >
        {currentVariation.images.map((img, i) => (
          <Img fluid={img.fluid} key={i} />
        ))}
      </div>
      <ImageCaption>{name}</ImageCaption>
      <ImageCaption>
        {variations.map(v => (
          <VariationContainer key={v.name}>
            <VariationLabel
              current={v.name === currentVariation.name}
              onClick={() => {
                setCurrentImage(0)
                setCurrentVariation(v)
              }}
            >
              {v.name}
            </VariationLabel>
          </VariationContainer>
        ))}
      </ImageCaption>
    </Container>
  )
}

export default ProductCard
