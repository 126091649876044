import styled from "styled-components"
import device from "../../util/devices"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  overflow-x: visible;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  article + article {
    margin-left: 10px;
    @media (${device.desktop}) {
      margin-left: 20px;
    }
  }

  @media (${device.phone}) {
    > :not(:first-child) {
      display: none;
    }
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > :first-child {
    flex: 1;
    max-width: 170px;
  }
`
