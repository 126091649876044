import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import BackgroundContext from "../context/BackgroundContext"
import * as S from "./Brand.styled"
import { StockistSet } from "./Brand.styled"
import Slideshow from "../components/Slideshow"
import DatoCmsText from "../components/DatoCmsText"
import { Body2 } from "../components/Texts"
import ProductCard from "../components/ProductCard"
import BrandCarousel from "../components/BrandCarousel"
// import Button from "../components/Button"
import ContactUsModal from "../components/ContactUsModal"
import PageTransition from "gatsby-plugin-page-transitions"
import styled from "styled-components"
import InlineSVG from "react-inlinesvg"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Bg = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    ${props =>
      props.linearBg
        .map(c => c.color && `${c.color.hex} ${c.stop}%`)
        .join(", ")}
  );
`

const About = ({ description }) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <S.Description>
        <S.ContentContainer>
          {<DatoCmsText WrapperComponent={Body2} text={description} />}
        </S.ContentContainer>
      </S.Description>

      {modalOpen && <ContactUsModal onClose={() => setModalOpen(false)} />}
    </>
  )
}

const CollectionHighlights = ({ products }) => {
  products = products
    .sort((a, b) => {
      if (a.order && b.order) return a.order - b.order
      if (a.order && !b.order) return -1
      if (b.order && !a.order) return 1
      return a.name.localeCompare(b.name)
    })
    .reduce((acc, curr) => {
      const found = acc.find(x => x.name === curr.productType.pluralName)
      if (found) {
        found.products.push(curr)
      } else {
        acc.push({
          name: curr.productType.pluralName,
          products: [curr],
        })
      }
      return acc
    }, [])

  return (
    <>
      <S.SectionTitle>Highlights of the collection</S.SectionTitle>
      {products.map(p => (
        <React.Fragment key={p.name}>
          <S.CategoryTitle>{p.name}</S.CategoryTitle>
          <S.HighlightsContainer count={p?.products.length || 50}>
            {p.products.map(p => (
              <ProductCard {...p} key={p.name} />
            ))}
          </S.HighlightsContainer>
        </React.Fragment>
      ))}
    </>
  )
}

const Stockist = ({ stockists }) => {
  stockists = stockists.reduce((accumulator, current) => {
    const entry = accumulator.find(x => x.country === current.country)

    if (!entry) {
      accumulator.push({
        country: current.country,
        addresses: [current.address],
      })
    } else {
      entry.addresses.push(current.address)
    }
    return accumulator
  }, [])

  const splitStockists = arr =>
    arr.length <= 2
      ? [arr]
      : [
          arr.slice(0, Math.round(arr.length / 2)),
          arr.slice(Math.round(arr.length / 2), arr.length),
        ]

  return (
    <>
      {splitStockists(stockists).map(st => (
        <StockistSet key={st.country} className="stock">
          {st.map((s, i) => (
            <React.Fragment key={i}>
              <Body2 as="h3">{s.country}</Body2>
              {s.addresses?.map((a, j) => (
                <DatoCmsText key={j} WrapperComponent={Body2} text={a} />
              ))}
            </React.Fragment>
          ))}
        </StockistSet>
      ))}
    </>
  )
}

export default ({
  data: { datoCmsBrand, products, datoCmsSite },
  location,
}) => {
  const { colors } = datoCmsBrand
  const bg = React.useContext(BackgroundContext)
  const { modal } = React.useContext(ModalRoutingContext)

  useEffect(() => {
    bg.setLinearBackgroundColors(colors)
    return () => bg.setLinearBackgroundColors(null)
  }, [modal, bg, colors])

  const [selectedTab, setSelectedTab] = useState(0)
  console.log("test=>", location.previous)
  return (
    <PageTransition>
      {!modal && <Bg linearBg={datoCmsBrand.colors} />}
      {modal && <Header light backOnly onBack={() => navigate(-1)} />}
      <S.Host as="main">
        <HelmetDatoCms
          seo={datoCmsBrand.seoMetaTags}
          favicon={datoCmsSite.faviconMetaTags}
        />
        <S.LayoutContainer>
          <S.Container>
            <S.ImageWrapper>
              <Slideshow images={datoCmsBrand.images}>
                {datoCmsBrand.logo && (
                  <InlineSVG
                    style={{ maxWidth: "250px" }}
                    src={datoCmsBrand.logo.url}
                  />
                )}
              </Slideshow>
            </S.ImageWrapper>
            <S.CloseButton
              onClick={() => (location.previous ? navigate(-1) : navigate("/"))}
            >
              Close
            </S.CloseButton>
            <S.Title>
              <S.ContentContainer>{datoCmsBrand.name}</S.ContentContainer>
            </S.Title>
            {selectedTab === 0 && <About {...datoCmsBrand} />}
            {selectedTab === 1 && <Stockist {...datoCmsBrand} />}
            <S.NavContainer>
              <S.ContentContainer>
                <S.Nav>
                  <S.TabButton plain>
                    <a
                      href={datoCmsBrand.website}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Website
                    </a>
                  </S.TabButton>
                  <S.TabButton plain>
                    <a
                      href={datoCmsBrand.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </S.TabButton>
                </S.Nav>
              </S.ContentContainer>
            </S.NavContainer>
          </S.Container>
        </S.LayoutContainer>
      </S.Host>
    </PageTransition>
  )
}

export const query = graphql`
  query($id: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    products: allDatoCmsProduct(filter: { brand: { id: { eq: $id } } }) {
      edges {
        node {
          order
          name
          productType {
            pluralName
          }
          variations {
            name
            images {
              fluid(maxWidth: 300, imgixParams: { auto: "compress" }) {
                ...GatsbyDatoCmsFluid
              }
              title
              alt
            }
          }
        }
      }
    }
    datoCmsBrand(id: { eq: $id }) {
      name
      description
      website
      instagram
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      logo {
        ...Image
        url
      }
      colors: pageBackground {
        color {
          hex
        }
        stop
      }
      images {
        ...Image
      }
      stockists {
        country
        address
      }
      related {
        ...BrandCard
      }
    }
  }
`
