import styled, { css } from "styled-components"
import device from "../util/devices"
import GridLayout from "../layout/GridLayout"
import { BrandTitle, ImageCaption, Title1 } from "../components/Texts"
import Button from "../components/Button"

export const Host = styled(GridLayout)`
  padding-bottom: 28px;
  position: relative;
  margin-top: clamp(27px, ${(37/888*100).toFixed(2)}vh, 37px);
  @media (${device.phone}) {
    width: clamp(290px, ${(366/390*100).toFixed(2)}vw, 1340px);
  }
  @media (${device.desktop}) {
    padding-bottom: 0;
  }
`

export const LayoutContainer = styled.div`
  background: #f5f5f3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  border-radius: 14px;
  overflow: hidden;
  > :first-child {
    margin-left: 0;
    margin-right: 0;
  }
  grid-column: 1 / 7;

  @media (${device.desktop}) {
    grid-column: 2 / 12;
  }

  #highlights {
    margin-top: 75px;
  }
`

export const Container = styled(GridLayout)`
  width: auto;
  &.no-grid {
    display: flex;
    flex-direction: column;
  }

  &#other,
  &#products {
    padding-top: 100px;
  }
  @media (${device.desktop}) {
    grid-template-columns: repeat(10, 1fr);
    grid-column: 2 / 10;
    grid-template-rows: auto minmax(0, 1fr) auto minmax(0, 1fr);
  }
`

export const HighlightsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;

  article {
    display: block;
  }

  ${props =>
    Array(props.count)
      .fill(0)
      .map(
        (_, i) => css`
          > :nth-child(${i + 1}) {
            grid-column: ${(i % 2) + 1};
            grid-row: ${Math.floor(i / 2) + 1};
          }
        `
      )};

  @media (${device.desktop}) {
    grid-template-columns: repeat(5, 1fr);
    ${props =>
      Array(props.count)
        .fill(0)
        .map(
          (_, i) => css`
            > :nth-child(${i + 1}) {
              grid-column: ${(i % 5) + 1};
              grid-row: ${Math.floor(i / 5) + 1};
            }
          `
        )};
  }
`

export const ImageWrapper = styled.div`
  grid-column: 1 / 7;
  grid-row: 1 / 6;
  position: relative;
  aspect-ratio: 366 / 312;
  overflow: hidden;
  > :first-child,
  > div .gatsby-image-wrapper {
    height: 100%;
  }
  @media (${device.desktop}) {
    aspect-ratio: auto;
    grid-column: 1 / 6;
    min-height: 100%;
    height: clamp(312px, calc(100vh - 91px), ${(653/888*100).toFixed(2)}vh);
  }
`

export const Title = styled(BrandTitle)`
  grid-row: 6;
  grid-column: 1 / 7;
  padding-top: 38px;
  @media (${device.desktop}) {
    padding-top: 0;
    grid-row: 2;
    grid-column: 6 / 11;
    align-self: end;
  }
`
export const Description = styled.div`
  grid-row: 7;
  grid-column: 1 / 7;
  margin-top: 15px;
  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 6 / 11;
    margin-top: 0;
    p:first-of-type {
      margin-top: 30px;
    }
  }
`
export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 360px;
  padding: 0 20px;
`

export const ContactButtonWrapper = styled.div`
  grid-row: 8;
  grid-column: 1 / 7;
  margin: 30px 10px 0;
  button {
    width: 100%;
  }
  @media (${device.desktop}) {
    grid-row: 4;
    grid-column: 7 / 9;
    margin: 40px 0 30px;

    button {
      min-width: 220px;
    }
  }
`

export const NavContainer = styled.div`
  grid-row: 9;
  grid-column: 1 / 7;
  margin-top: 20px;
  margin-bottom: 29px;
  @media (${device.desktop}) {
    grid-row: 5;
    grid-column: 6 / 11;
    margin-top: auto;
  }
`;
export const Nav = styled.nav`
  grid-column-gap: clamp(40px, ${(90/1440*100).toFixed(2)}vw, 90px);
  display: flex;
  flex-direction: row;
  justify-content: start;
`

export const CloseButton = styled(ImageCaption)`
  color: inherit;
  cursor: pointer;
  text-align: right;
  padding-right: 2em;
  position: absolute;
  right: 0;
  top: 16px;
  color: white;
  @media (${device.desktop}) {
    position: relative;
    top: 0;
    color: inherit;
    grid-row: 1;
    padding-top: 25px;
    grid-column: 10;
  }
`

const plainButton = css`
  border: none;
  padding: 0;
  min-width: 0;
  font-size: 15px;
  line-height: 22px;
  background: none;
  color: inherit;

  &:hover {
    background: transparent;
    color: inherit;
    text-decoration: underline;
  }
  ${props =>
    props.current &&
    css`
      color: black;
      text-decoration: underline;
    `};
`

export const TabButton = styled(Button)`
  ${props =>
    props.plain
      ? plainButton
      : props.current &&
        css`
          background: #171717;
          color: white;
        `};

  a {
    color: inherit;
    text-decoration: none;
  }
  width: auto;
  ${plainButton};
  font-size: 13px;
`

export const StockistSet = styled.div`
  padding-left: 10px;
  grid-column: 1 / 7;
  grid-row: 7;
  @media (${device.desktop}) {
    padding-left: 0;
    grid-column: 7 / 9;
    grid-row: 3 / 5;
    + .stock {
      grid-column: 9 / 11;
    }
  }

  h3 {
    text-transform: uppercase;
    margin: 20px 0;
  }

  a {
    color: inherit;
  }
`

export const SectionTitle = styled(Title1)`
  font-family: inherit;
  grid-column: 1 / 7;
`

export const CategoryTitle = styled(ImageCaption)`
  color: inherit;
  grid-column: 1 / 7;
  margin-bottom: 15px;
  @media (${device.desktop}) {
    padding-top: 12px;
  }
`
